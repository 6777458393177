import { Routes } from '@angular/router';
import { LoggedInGuard } from './ui/pages/main/logged-in-guard.service';
import { AuthPartGuard } from './ui/pages/auth/auth-part-guard.service';
import { NotFoundRouteGuardService } from './routing/not-found-route-guard.service';
import { NotFoundRouteComponent } from './ui/pages/404/not-found-route/not-found-route.component';
import { OAuthPartGuard } from './ui/pages/oauth/oauth-part-guard.services';
const ɵ0 = () => import("./ui/pages/main/main.module.ngfactory").then(m => m.MainModuleNgFactory), ɵ1 = () => import("./ui/pages/auth/auth.module.ngfactory").then(m => m.AuthModuleNgFactory), ɵ2 = () => import("./ui/pages/oauth/oauth.module.ngfactory").then(m => m.OAuthModuleNgFactory), ɵ3 = () => import("./ui/pages/acceptance/acceptance-start.module.ngfactory").then(m => m.AcceptanceStartModuleNgFactory), ɵ4 = () => import("./ui/pages/smartredirect/smartredirect.module.ngfactory").then(m => m.SmartRedirectModuleNgFactory), ɵ5 = () => import("./ui/pages/confirmation/confirmation.module.ngfactory").then(m => m.ConfirmationModuleNgFactory);
const routes = [
    {
        path: '',
        loadChildren: ɵ0,
        canActivate: [LoggedInGuard],
    },
    {
        path: 'auth',
        loadChildren: ɵ1,
        canActivate: [AuthPartGuard]
    },
    {
        path: 'oauth',
        loadChildren: ɵ2,
        canActivate: [OAuthPartGuard],
    },
    {
        path: 'acceptance',
        loadChildren: ɵ3,
    },
    {
        path: 'smartredirect',
        loadChildren: ɵ4,
    },
    {
        path: 'confirmation',
        loadChildren: ɵ5,
    },
    {
        path: '**',
        // use empty component and guard for logging this situation. It'll help us to understand wrong links
        component: NotFoundRouteComponent,
        canActivate: [NotFoundRouteGuardService]
    },
];
export class AppRoutingModule {
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5 };
