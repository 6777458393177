import {
  CompanyOrderGeneralTicketsDistributionPersonalTokenAPIModel,
  CompanyOrderGeneralTicketsDistributionTokenAPIModel
} from '../data/services/api/models/company/orders/CompanyOrderTicketDistributionAPIModel';

export const REFERRAL_URL_PARAM_NAME = 'ref';
export const OPENING_CONTEXT_PARAM_NAME = 'from';
export const FROM_PARAM_NAME = 'from';
export const REGISTRATION_CONFIRMATION_KEY_PARAM_NAME = 'key';
export const RESTORATION_CONFIRMATION_KEY_PARAM_NAME = 'key';
export const LANGUAGE_URL_PARAM_NAME = 'lang';
export const TICKET_ACCEPTANCE_TOKEN_PARAM_NAME = 'key';
export const EMAIL_CONFIRMATION_TOKEN_PARAM_NAME = 'key';


export const ROUTES_PATHS = {
  main: ['/'],

  // auth
  login: ['/auth/login'],

  registrationStart: ['/auth/registration'],
  registrationStarted: ['/auth/registration/started'],
  // it's hardcoded on server side! don't change this
  registrationConfirmation: ['/auth/registration/confirmation'],

  restorationStart: ['/auth/restoration'],
  restorationStarted: ['/auth/restoration/started'],
  // it's hardcoded on server side! don't change this
  restorationConfirmation: ['/auth/restoration/confirmation'],

  // oauth
  oauth: ['/oauth'],
  oauthLogin: ['/oauth/login'],
  oauthRegistration: ['/oauth/registration'],
  confirmation: ['/confirmation'],

  // user
  userProfileFirstTimeFilling: ['/user/profile/filling/first-time'],
  userPasswordSetting: ['/user/profile/filling/password-setting'],
  userProfileInfo: ['/user/profile'],
  userEditingBaseProfile: ['/user/profile/editing/base'],
  userEditingProfileContacts: ['/user/profile/editing/contacts'],
  mergeLk: [`user/profile/merge`],
  userPasswordChanging: ['/user/profile/password-changing'],
  userPasswordRestorationStarted: ['/user/profile/password-restoration/started'],
  userPasswordRestorationConfirmation: ['/user/profile/password-restoration/confirmation'],

  userProfileBaseInfo: ['/user/profile/base'],
  userProfileContactsInfo: ['/user/profile/contacts'],
  userProfileSecurityInfo: ['/user/profile/security'],
  userProfileShareInfo: ['/user/profile/share'],

  // tickets
  tickets: ['/tickets'],
  ticketsOffers: ['/tickets/offers'],
  ticketsOfferAcceptanceStart: ['/tickets/offers/acceptance/start'],

  // acceptance
  acceptanceTicket: ['/acceptance/ticket'],

  // company
  companies: ['/companies'],

  // administration
  administration: ['/administration'],
  administrationCompanies: ['/administration/companies'],
};

export const DYNAMIC_ROUTES_PATHS = {

  // tickets
  ticketOffer: ticketOfferId => [`/tickets/offers/${ticketOfferId}`],
  ticketOfferConfirmation: ticketOfferId => [`/tickets/offers/${ticketOfferId}/confirmation`],

  // companies
  company: companyId => [ `/companies/${companyId}` ],
  companyOrders: companyId => [ `/companies/${companyId}/orders` ],
  companyOrder: (
    companyId: number,
    orderId: number,
  ) => [`/companies/${companyId}/orders/${orderId}`],
  companyOrderTickets: (
    companyId: number,
    orderId: number,
  ) => [`/companies/${companyId}/orders/${orderId}/tickets`],
  companyOrderInformation: (
    companyId: number,
    orderId: number,
  ) => [`/companies/${companyId}/orders/${orderId}/information`],
  companyProfile: companyId => [ `/companies/${companyId}/profile` ],


  // administration
  administrationCompany: companyId => [ `/administration/companies/${companyId}` ],
  administrationCompanyOrders: companyId => [ `/administration/companies/${companyId}/orders` ],
  administrationCompanyProfile: companyId => [ `/administration/companies/${companyId}/profile` ],
  administrationCompanyRoles: companyId => [ `/administration/companies/${companyId}/roles` ],

  administrationCompanyOrder: (
    companyId: number,
    orderId: number,
  ) => [ `/administration/companies/${companyId}/orders/${orderId}` ],
  administrationCompanyOrderTickets: (
    companyId: number,
    orderId: number,
  ) => [ `/administration/companies/${companyId}/orders/${orderId}/tickets` ],
  administrationCompanyOrderInformation: (
    companyId: number,
    orderId: number,
  ) => [ `/administration/companies/${companyId}/orders/${orderId}/information` ],
};

/**
 * DON'T TOUCH THIS WITHOUT APPROVE
 * set of links which client and backend sides must generate and synchronize
 */
export const FUNCTIONAL_LINKS = {

  ticketsDistributionAcceptanceLink: (
    token: CompanyOrderGeneralTicketsDistributionTokenAPIModel
      | CompanyOrderGeneralTicketsDistributionPersonalTokenAPIModel,
  ) => `${window.location.origin}/acceptance/ticket/?${TICKET_ACCEPTANCE_TOKEN_PARAM_NAME}=${token}`,

};

